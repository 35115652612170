
import { Options, Vue } from 'vue-class-component';
import { ServiceSoldConfigurationClient } from '@/services/Services';
import EditModal from '../modals/editModal.vue';
import * as OM from '@/Model';


@Options({
    components: {}
})
export default class ServicesSoldCatalog extends Vue {

    items: OM.ServiceSoldVM[] = [];
    garments: OM.TextIdentifier[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        Promise.all([
            ServiceSoldConfigurationClient.getAllConfigurations()

        ]).then(xs =>{
            this.items = xs[0];
            this.loaded = true;

        })
    }

    buyItem(item: OM.ServiceSoldVM) {
        if(!item)
            item = new OM.ServiceSoldVM();

        this.$opModal.show(EditModal, {
            model: item,
            garmentsList: this.garments,
            callback: () => {
                this.$router.push('/servicesHistory');
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }
        })
    }
    
    priceWithoutDiscount(price: number, discount: number){
        if(!discount)
            return price;

        return (price * 100) / (100 - discount);
    }

    priceWithoutDiscountPerMonth(price: number, discount: number, month: number){
        if(!discount)
            return price;

        return this.priceWithoutDiscount(price, discount) / month;
    }
    
    priceWithoutDiscountPerUserPerMonth(price: number, discount: number, month: number, users: number){
        if(!discount)
            return price;

        return this.priceWithoutDiscountPerMonth(price, discount, month) / users;
    }
}
