
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { StripeClient } from '@/services/Services';
import optionsSelector from '@/components/optionsSelector.vue';
import * as OM from '@/Model';
import StripeForm from '@/components/stripe/stripeForm.vue';
@Options({
    components:{StripeForm}
})
export default class EditModal extends Vue {

    @Prop({
        default: () => new OM.ServiceSoldVM()
    }) model: OM.ServiceSoldVM;

    @Prop() garmentsList: OM.TextIdentifier[];

    localModel: OM.ServiceSoldVM = new OM.ServiceSoldVM();
    garmentsListLocal: OM.TextIdentifier[];
    paymentId: string = "";

    @Prop() callback: any;

    created() {
        this.localModel = this.model;
        this.garmentsListLocal = this.garmentsList;
    }

    get canSave(){
        return this.localModel.garmentsCalculation && this.localModel.monthDuration  && this.localModel.price && this.localModel.userLicense;
    }

    intentCb(){
        return StripeClient.createIntent(this.localModel.identifier)
        .then(x => {
            this.paymentId = x.paymentId;
            return x.clientSecret;
        });
    }
    onPayComplete(){
        StripeClient.completeBuyServiceSold({
            paymentId: this.paymentId,
            userIdentifier: this.$store.state.loggedUser.identifier
        })
        .then(x => {
            this.$opModal.closeAll();
            this.callback();
        })
    }
    
}
